import { EntityType, GroupType } from '@packages/types'
import { createSelector } from 'reselect'

import { utils as customizationUtils, selectors as customizationSelectors } from 'customizer/customization'
import { selectors as questionPanelSelectors } from 'customizer/questionPanel'

export const stepsSelector = state => {
  const questions = questionPanelSelectors.productAsQuestionPanelSelector(state, { maxNestingLevel: 0 })
  if (!questions) return []

  const filteredQuestions = questions.filter(question => {
    return question.entityType === EntityType.Group && question.type === GroupType.BulkOrder
      ? question
      : customizationUtils.stepHasQuestionWithAvailableAnswers(question)
  })

  return filteredQuestions.map(step => {
    const restrictedAnswers = customizationUtils.getQuestionRestrictedAnswers(step)

    return {
      ...step,
      answers: Array.isArray(step.answers)
        ? step.answers.filter(({ id, archived }) => !restrictedAnswers.includes(id) && archived !== true)
        : [],
    }
  })
}

export const selectedStepIdSelector = createSelector(
  questionPanelSelectors.currentStepSelector,
  stepsSelector,
  (currentStep, steps) => {
    const foundStep = steps.find(step => {
      const isBulkOrder = step.entityType === EntityType.Group && step.type === GroupType.BulkOrder
      return step.id === currentStep?.id || (isBulkOrder && !!step.children.find(child => child.id === currentStep?.id))
    })

    return foundStep?.id ?? steps[0]?.id
  }
)

export const invalidStepsSelector = createSelector(
  stepsSelector,
  questionPanelSelectors.showInteractionRequiredWarningsSelector,
  questionPanelSelectors.invalidQuestionsSelector,
  questionPanelSelectors.outOfStockStepsSelector,
  customizationSelectors.questionsRequiringInteractionSelector,
  questionPanelSelectors.questionsOverAvailableStocksSelector,
  (
    availableSteps,
    showInteractionRequiredWarnings,
    requiredQuestions,
    invalidQuestions,
    outOfStockSteps,
    questionsOverAvailableStocks
  ) => {
    if (!showInteractionRequiredWarnings) return []

    const isQuestionInvalid = question => {
      return (
        !!requiredQuestions.find(requiredQuestion => requiredQuestion.id === question.id) ||
        !!invalidQuestions.find(invalidQuestion => invalidQuestion.id === question.id) ||
        !!outOfStockSteps.find(outOfStockQuestion => outOfStockQuestion.id === question.id) ||
        !!questionsOverAvailableStocks.find(questionsOverAvailable => questionsOverAvailable.id === question.id)
      )
    }

    return availableSteps.reduce((steps, step) => {
      if (step.entityType === EntityType.Group && step.type === GroupType.BulkOrder) {
        const invalidBulkOrderSteps = step.children.reduce((questions, question) => {
          return isQuestionInvalid(question) ? [...questions, question.id] : questions
        }, [])

        return [...steps, ...invalidBulkOrderSteps]
      }

      return isQuestionInvalid(step) ? [...steps, step.id] : steps
    }, [])
  }
)
