import { DenormalizedBulkOrder } from '@packages/types'
import classNames from 'classnames'
import React, { useRef } from 'react'

import { bulkOrderTotalQuantitySelector } from 'customizer/bulkOrder/selectors'
import { useDispatch, useSelector } from 'customizer/hooks'
import { selectStep } from 'customizer/questionPanel/actions'
import { useThemeSettings } from 'customizer/theme'
import { Translation } from 'themes/common/components'
import { BottomDrawerValues } from 'themes/common/components/bottomDrawer'
import { useBulkOrderValidation } from 'themes/common/hooks'

import BulkOrderQuantityAndErrorMessage from '../bulkOrder/BulkOrderQuantityAndErrorMessage'
import SummaryDrawerStepStatus from './SummaryDrawerStepStatus'

export type SummaryDrawerBulkOrderProps = {
  drawer: BottomDrawerValues
  bulkOrder: DenormalizedBulkOrder
}

const SummaryDrawerBulkOrder = ({ drawer, bulkOrder }: SummaryDrawerBulkOrderProps) => {
  const dispatch = useDispatch()
  const ref = useRef<HTMLButtonElement>(null)
  const styles = useThemeSettings('summaryDrawer')

  const total = useSelector(bulkOrderTotalQuantitySelector)

  const { hasError, hasRequiredErrors, isSelectionOutOfStock, hasAnswersOverAvailableStocks } = useBulkOrderValidation()

  const handleQuestionClick = () => {
    dispatch(selectStep(bulkOrder.id))
    drawer.dismiss()
  }

  return (
    <button
      ref={ref}
      className={classNames('summary-drawer__step-container', { 'summary-drawer__step-error': hasError })}
      onClick={handleQuestionClick}
      style={{ borderColor: styles.dividerColor }}
    >
      <div className="summary-drawer__step">
        <div className="summary-drawer__step-title" style={{ color: styles.questionColor }}>
          {bulkOrder.name}
        </div>
        <div className="summary-drawer__step-answer" style={{ color: styles.answerColor }}>
          {total} <Translation field="items" />
        </div>

        {hasError && !hasRequiredErrors && (
          <div className="summary-drawer__step-message">
            <BulkOrderQuantityAndErrorMessage
              bulkOrderGroup={bulkOrder}
              shouldDisplayQuantityMessage
              shouldDisplayStockMessage
              shouldDisplayWordFilterMessage
            />
          </div>
        )}
      </div>

      <SummaryDrawerStepStatus
        isRequired={hasRequiredErrors}
        hasError={hasError}
        isOutOfStock={isSelectionOutOfStock || hasAnswersOverAvailableStocks}
      />
    </button>
  )
}

export default SummaryDrawerBulkOrder
