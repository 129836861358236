import { DenormalizedCustomizableQuestion, QuestionInputType } from '@packages/types'
import classNames from 'classnames'
import React, { useRef } from 'react'

import { useDispatch, useSelector } from 'customizer/hooks'
import { actions as questionPanelActions, selectors as questionPanelSelectors } from 'customizer/questionPanel'
import { shouldDisplayOutOfStockErrorSelector } from 'customizer/questionPanel/selectors'
import { useThemeSettings } from 'customizer/theme'
import { invalidStepsSelector } from 'themes/booklike/questionPanel/selectors'
import { BottomDrawerValues } from 'themes/common/components/bottomDrawer'

import { getDisplayedAnswer } from '../answers'
import SummaryDrawerQuestionMessage from './SummaryDrawerQuestionMessage'
import SummaryDrawerStepStatus from './SummaryDrawerStepStatus'

const { selectStep } = questionPanelActions

export type SummaryDrawerQuestionProps = {
  drawer: BottomDrawerValues
  question: DenormalizedCustomizableQuestion
}

const SummaryDrawerQuestion = ({ question, drawer }: SummaryDrawerQuestionProps) => {
  const dispatch = useDispatch()
  const ref = useRef<HTMLButtonElement>(null)
  const summaryDrawerStyle = useThemeSettings('summaryDrawer')

  const visitedQuestions = useSelector(questionPanelSelectors.visitedQuestionsSelector)
  const isOutOfStock = useSelector(state => shouldDisplayOutOfStockErrorSelector(state, question.id))
  const invalidQuestions: string[] = useSelector(invalidStepsSelector)

  const isInvalid = invalidQuestions.includes(question.id)

  const { value } = getDisplayedAnswer(question, { showMultiAnswerValues: true })

  const handleQuestionClick = () => {
    dispatch(selectStep(question.id))
    drawer.dismiss()
  }

  return (
    <button
      ref={ref}
      className={classNames('summary-drawer__step-container', { 'summary-drawer__step-error': isInvalid })}
      onClick={handleQuestionClick}
      style={{ borderColor: summaryDrawerStyle.dividerColor }}
    >
      <div className="summary-drawer__step">
        <div className="summary-drawer__step-title" style={{ color: summaryDrawerStyle.questionColor }}>
          {question.name}
        </div>

        {value && (
          <div
            className={classNames('summary-drawer__step-answer', {
              'summary-drawer__step-answer--ellipsis': question.inputType === QuestionInputType.File,
            })}
            style={{ color: summaryDrawerStyle.answerColor }}
          >
            {value}
          </div>
        )}

        <SummaryDrawerQuestionMessage
          question={question}
          visited={visitedQuestions.includes(question.id)}
          hasError={isInvalid}
          isOutOfStock={isOutOfStock}
        />
      </div>

      <SummaryDrawerStepStatus
        isRequired={question.isRequired || question.isInteractionRequired}
        visited={visitedQuestions.includes(question.id)}
        hasError={isInvalid}
        isOutOfStock={isOutOfStock}
      />
    </button>
  )
}

export default SummaryDrawerQuestion
